<script lang="ts" setup generic="T extends undefined | null | any">
/**
 * UiOptional is a drop-in replacement for the UI pattern:
 *
 * ```
 * <template v-if="something">
 *   <!-- ... -->
 * </template>
 * <USkeleton v-else>
 * ```
 *
 * Usage should look like:
 *
 * ```
 * <UiOptional v-slot="{ value }" :value="something">
 *   <!-- ... -->
 * </UiOptional>
 * ```
 *
 * It ensures a consistent usage of the skeleton component and makes testing loading states easier.
 */

import { twMerge } from 'tailwind-merge'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  value: T
  skeleton?: any
}>()

defineSlots<{
  default: (props: { value: T extends (null | undefined) ? never : T }) => any
  skeleton: () => any
}>()

const definedValue = computed(() => props.value as T extends (null | undefined) ? never : T)
const skeleton = computed(() => {
  return {
    ...(props.skeleton || {}),
    class: twMerge('h-7 w-[150px] mx-auto', props.skeleton?.class),
  }
})
</script>

<template>
  <slot v-if="value" :value="definedValue" />
  <slot v-else name="skeleton">
    <USkeleton v-bind="skeleton" class="bg-forgd-gray-350" :ui="{ rounded: 'rounded-full' }" />
  </slot>
</template>
